<template>
  <shipblu-table
    :data="overview"
    orders
    multiple
    v-model="selected"
    :tableLoader="overviewTableLoader"
  >
    <template slot="thead">
      <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
      <shipblu-th>{{$t('Pickups')}}</shipblu-th>
      <shipblu-th>{{$t('Returns')}}</shipblu-th>
      <shipblu-th>{{$t('RTOs')}}</shipblu-th>
      <shipblu-th>{{$t('Supplies Request')}}</shipblu-th>
      <shipblu-th>{{$t('Phone')}}</shipblu-th>
      <shipblu-th>{{$t('Zone')}}</shipblu-th>
      <shipblu-th>{{$t('Address')}}</shipblu-th>
    </template>

    <template slot-scope="{ data }">
      <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
        <shipblu-td :data="data[indextr].name">
          {{ data[indextr].merchant.name }} {{ data[indextr].merchant.display_name ? '- ' + data[indextr].merchant.display_name : '' }}
        </shipblu-td>
        <shipblu-td :data="data[indextr].pickups">
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ data[indextr].pickups_total }}</span>  / 
            <span class="text-warning">{{ data[indextr].in_progress_delivery_pickups }}</span>  / 
            <span class="text-danger">{{ data[indextr].delivery_pickups_postponed }}</span> / 
            <span class="text-success">{{ data[indextr].successful_delivery_pickups }}</span>
          </div>
        </shipblu-td>
        <shipblu-td :data="data[indextr].returns">
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ data[indextr].returns_total }}</span>  / 
            <span class="text-warning">{{ data[indextr].in_progress_returns }}</span>  / 
            <span class="text-orange-700">{{ data[indextr].postponed_returns }}</span> / 
            <span class="text-danger">{{ data[indextr].failed_returns }}</span> / 
            <span class="text-success">{{ data[indextr].successful_returns }}</span>
          </div>
        </shipblu-td>
        <shipblu-td :data="data[indextr].rtos">
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ data[indextr].rtos_total }}</span>  / 
            <span class="text-warning">{{ data[indextr].in_progress_rtos }}</span>  / 
            <span class="text-orange-700">{{ data[indextr].postponed_rtos }}</span> / 
            <span class="text-danger">{{ data[indextr].failed_rtos }}</span> / 
            <span class="text-success">{{ data[indextr].successful_rtos }}</span>
          </div>
        </shipblu-td>
        <shipblu-td :data="data[indextr].supplies">
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ data[indextr].supplies_total }}</span>  / 
            <span class="text-warning">{{ data[indextr].in_progress_supplies }}</span>  / 
            <span class="text-danger">{{ data[indextr].failed_supplies }}</span> / 
            <span class="text-success">{{ data[indextr].successful_supplies }}</span>
          </div>
        </shipblu-td>
        <shipblu-td :data="data[indextr].phone">
          {{ data[indextr].merchant.store_phone }}
        </shipblu-td>
        <shipblu-td :data="data[indextr].zone">
          {{ $t(data[indextr].merchant_point.address.zone.name) }}
        </shipblu-td>
        <shipblu-td :data="data[indextr].address">
          {{ data[indextr].merchant_point.address.line_1 }}
        </shipblu-td>
      </shipblu-tr>
      <shipblu-tr class="total-row">
        <shipblu-td>
          <span class="font-semibold">{{$t('Total')}}</span>
        </shipblu-td>
        <shipblu-td>
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ totalRow.pickups.assigned }}</span>  / 
            <span class="text-warning">{{ totalRow.pickups.inProgress }}</span>  / 
            <span class="text-danger">{{ totalRow.pickups.refused }}</span> / 
            <span class="text-success">{{ totalRow.pickups.completed }}</span>
          </div>
        </shipblu-td>
        <shipblu-td>
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ totalRow.returns.assigned }}</span>  / 
            <span class="text-warning">{{ totalRow.returns.inProgress }}</span>  / 
            <span class="text-orange-700">{{ totalRow.returns.postponed }}</span> / 
            <span class="text-danger">{{ totalRow.returns.refused }}</span> / 
            <span class="text-success">{{ totalRow.returns.completed }}</span>
          </div>
        </shipblu-td>
        <shipblu-td>
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ totalRow.rtos.assigned }}</span>  / 
            <span class="text-warning">{{ totalRow.rtos.inProgress }}</span>  / 
            <span class="text-orange-700">{{ totalRow.rtos.postponed }}</span> / 
            <span class="text-danger">{{ totalRow.rtos.refused }}</span> / 
            <span class="text-success">{{ totalRow.rtos.completed }}</span>
          </div>
        </shipblu-td>
        <shipblu-td>
          <div class="flex gap-1 justify-center">
            <span class="text-black">{{ totalRow.supplies.assigned }}</span>  / 
            <span class="text-warning">{{ totalRow.supplies.inProgress }}</span>  / 
            <span class="text-danger">{{ totalRow.supplies.refused }}</span> / 
            <span class="text-success">{{ totalRow.supplies.completed }}</span>
          </div>
        </shipblu-td>
        <shipblu-td>
        </shipblu-td>
      </shipblu-tr>
    </template>
  </shipblu-table>
</template>

<script>
import ShipbluTable from '../../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'

export default {
  props: ['overview', 'suppliesDic', 'overviewTableLoader', 'totalRow'],
  data () {
    return {
      selected: []
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  }
}
</script>

<style lang="scss" scoped>
.total-row {
  position: relative;
  td {
    padding-top: 20px;
  }
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    height: 0px;
    border-top: 1px dashed #1c5bfe;
    width: 100%;
  }
  & > :first-child {
    visibility: hidden !important;
  }
}
</style>

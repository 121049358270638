<template>
  <div>
    <div class="grid lg:grid-cols-6 sm:grid-cols-4 grid-cols-2 gap-4 mb-6">
      <shipblu-card v-for="(slot, index) in slots" :key="index">
        <div @click="showPackages(warehouseUnitsDic[slot[0]])" class="text-center p-4" :class="warehouseUnitsDic[slot[0]].name === 'slots' ? 'cursor-pointer' : ''">
          <p class="text-primary font-semibold">{{slot[0]}}</p>
          <p class="text-blue-900 font-medium">{{slot[1]}}</p>
        </div>
      </shipblu-card>
    </div>
    <shipblu-table
      :data="taskSheetOrders"
      multiple
      :tableLoader="tableLoader"
      v-model="selected"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th v-if="taskListInfoData.customer_agent">{{ $t('Customer')}}</shipblu-th>
        <shipblu-th>{{$t('Phone Number')}}</shipblu-th>
        <shipblu-th>{{$t('Zone')}}</shipblu-th>
        <shipblu-th>{{$t('Address')}}</shipblu-th>
        <shipblu-th>{{$t('No. Packages')}}</shipblu-th>
        <shipblu-th v-if="taskListInfoData.customer_agent">{{$t('Cash')}}</shipblu-th>
        <shipblu-th v-if="taskListInfoData.customer_agent">{{$t('Delivery Window')}}</shipblu-th>
        <shipblu-th v-if="taskListInfoData.customer_agent">{{$t('Delivery Time')}}</shipblu-th>
        <shipblu-th>{{$t('GPS Loaction')}}</shipblu-th>
        <shipblu-th>{{$t('Status')}}</shipblu-th>
        <shipblu-th v-if="taskListInfoData.status !== 'completed' && taskListInfoData.status !== 'failed' && $store.state.AppActiveUser.userRole !== 'finance'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`"
          :to="{ name: `${$store.state.AppActiveUser.userRole}-order`, params: { warehouseID: $route.params.warehouseID, taskListID: $route.params.taskListID, type: data[indextr].tracking_number[0] === '2' || (data[indextr].tracking_number[0] === '3' && data[indextr].tracking_number[1] === '2') ? 'returns' : data[indextr].tracking_number[0] === '4' ? 'cash-collections' : 'delivery-orders', orderID: data[indextr].id}, query: {last_mile: taskListInfoData.customer_agent ? true : false} }">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-20 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].tracking_number }}
                <span @click="copied(data[indextr].tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].merchant">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant')}}</p>
            <p class="sm:text-base text-sm">{{ data[indextr].merchant.name }} {{ data[indextr].merchant.display_name ? '- ' + data[indextr].merchant.display_name : '' }}</p>
          </shipblu-td>
          
          <shipblu-td class="order-5" v-if="taskListInfoData.customer_agent" :data="data[indextr].customerName">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer')}}</p>
              <p class="sm:text-base text-sm">{{ data[indextr].customer.full_name }}</p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-5" v-if="taskListInfoData.customer_agent" :data="data[indextr].customerPhone">
            <div v-on:click.stop>
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Phone Number')}}</p>
              <p class="flex sm:text-base text-sm gap-1">
                {{ data[indextr].customer.phone }}
                <span @click="copied(data[indextr].customer.phone)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td>

          <shipblu-td class="order-6" v-if="taskListInfoData.customer_agent" :data="data[indextr].zone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zone')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].customer.address.zone && data[indextr].customer.address.zone.name ? $t(data[indextr].customer.address.zone.name) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-7" v-if="taskListInfoData.customer_agent" :data="data[indextr].loaction">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Address')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].customer.address.line_1 }} / {{ data[indextr].customer.address.line_2 }}
            </p>
          </shipblu-td>
          <shipblu-td class="order-8" v-if="taskListInfoData.merchant_agent" :data="data[indextr].phone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Phone')}}</p>
            <p class="sm:text-base text-sm">
            {{ data[indextr].merchant.store_phone }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-9" v-if="taskListInfoData.merchant_agent" :data="data[indextr].zone">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Zone')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].merchant_point && data[indextr].merchant_point.address ? $t(data[indextr].merchant_point.address.zone.name) + $t(data[indextr].merchant_point.address.zone.name) : 'N/A' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-10" v-if="taskListInfoData.merchant_agent" :data="data[indextr].loaction">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Address')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].merchant_point && data[indextr].merchant_point.address ? data[indextr].merchant_point.address.line_1 : 'N/A' }} / {{ data[indextr].merchant_point ? data[indextr].merchant_point.address.line_2 : 'N/A' }} 
            </p>
            </shipblu-td>
          <shipblu-td class="order-11" :data="data[indextr].noPackages">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('No. Packages')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].number_of_packages }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-12" v-if="taskListInfoData.customer_agent" :data="data[indextr].cash">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Cash')}}</p>
            <div class="flex items-center">
              <p class="sm:text-base text-sm">{{ data[indextr].tracking_number[0] !== '4' && data[indextr].cash_amount > 0 ? (data[indextr].cash_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : data[indextr].tracking_number[0] === '4' ? (data[indextr].amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) :'N/A' }}</p>
              <span v-if="data[indextr].is_paid" class="sm:text-base text-sm material-symbols-outlined ml-2">credit_card</span>
            </div>
          </shipblu-td>

          <shipblu-td class="order-13" v-if="taskListInfoData.customer_agent" :data="data[indextr].preferred_window">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Delivery Window')}}</p>
            <span class="sm:text-base text-sm" v-for="window in data[indextr].preferred_window" :key="window.index">
              {{window.display_name}}<br/>
            </span>
          </shipblu-td>

          <shipblu-td class="order-14" :data="data[indextr].deliveryTime" v-if="taskListInfoData.customer_agent">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Delivery Time')}}</p>
            <p class="sm:text-base text-sm" v-if="data[indextr].tracking_number[0] === '1' || (data[indextr].tracking_number[0] === '3' && data[indextr].tracking_number[1] === '1')">
              {{ data[indextr].delivered_date ? new Date(data[indextr].delivered_date).getHours() + ':' + new Date(data[indextr].delivered_date).getMinutes() : '' }}
            </p>
            <p class="sm:text-base text-sm" v-else>
              {{ data[indextr].returned_date ? new Date(data[indextr].returned_date).getHours() + ':' + new Date(data[indextr].returned_date).getMinutes() : '' }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-15" :data="data[indextr].customerLink">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('GPS Location')}}</p>
            <a class="sm:text-base text-sm" target="blank" :href="taskListInfoData.customer_agent? `https://www.google.com/maps/search/?api=1&query=${data[indextr].customer.address.line_1},${data[indextr].customer.address.zone ? data[indextr].customer.address.zone.name : ''}`: `https://www.google.com/maps/search/?api=1&query=${data[indextr].merchant_point && data[indextr].merchant_point.address ? data[indextr].merchant_point.address.line_1 : ''},${data[indextr].merchant_point && data[indextr].merchant_point.address ? data[indextr].merchant_point.address.zone.name : ''}`">{{('Map Location')}}</a>
          </shipblu-td>
          
          <shipblu-td class="order-16" v-if="(taskListInfoData.status !== 'completed' && taskListInfoData.status !== 'failed') || new Date(taskListInfoData.created).toISOString() < new Date('02/09/2022').toISOString()" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr])}`" style="white-space: nowrap;">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Status')}}</p>
            <div class="flex" style="width: fit-content;" @click="removeAttribute($event), openNotificationFailureData(data[indextr])">
              <span class="capitalize border border-solid rounded-4xl px-5 py-1.5 leading-none sm:text-sm text-xs text-white" :style="`background-color: ${getStyle(data[indextr])}`">{{ $t(getStatusLabel(data[indextr])) }}</span>
            </div>
          </shipblu-td>
          <shipblu-td class="order-17" v-else :data="data[indextr].status">
            <vs-chip
            :color="getOrderOperationsStatusColor(data[indextr])"
            class="product-order-status">
              {{ data[indextr].operationsStatus }}
            </vs-chip>
          </shipblu-td>
          <shipblu-td class="order-2" v-if="taskListInfoData.status !== 'completed' && taskListInfoData.status !== 'failed' &&  $store.state.AppActiveUser.userRole !== 'finance'">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="viewShipment(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EyeIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('View')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="new Date().toISOString().split('T')[0] === taskListInfoData.created.split('T')[0] && data[indextr].status === 'delivered' && taskListInfoData.status === 'in_progress' && !taskList.cashCollected && new Date().getHours() < 22"
                    @click="confirmRemoveDeliveryStatus(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="RepeatIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Remove delivery status')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="new Date().toISOString().split('T')[0] === taskListInfoData.created.split('T')[0] && (data[indextr].tracking_number[0] === '2' || (data[indextr].tracking_number[0] === '3' && data[indextr].tracking_number[1] === '2')) && (data[indextr].status === 'picked_up' || data[indextr].status === 'in_transit') && taskListInfoData.status === 'in_progress' && data[indextr].cash_amount === 0 && new Date().getHours() < 22"
                    @click="confirmRemovePickedUpStatus(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="RepeatIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Remove latest status')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    v-if="common.formatDate(taskListInfoData.created) === common.formatDate(new Date().toJSON())"
                    :disabled="disableRemoveOrMoveOrders(data[indextr])"
                    @click="moveOrderToTaskSheet(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="RepeatIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Move order to another task sheet')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination v-if="!taskListInfoData.customer_agent" :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />
  </div>
</template>

<script>
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import common from '../../../assets/utils/common'
import { sendRequest } from '../../../http/axios/requestHelper'
import i18nData from '../../../i18n/i18nData'
import ShipbluPagination from '../../../layouts/components/ShipbluPagination.vue'
import ShipbluCard from '../../../layouts/components/ShipbluCard.vue'

export default {
  props: ['taskList', 'taskSheetOrders', 'selected', 'tableLoader', 'taskListInfoData', 'totalRows', 'currentPage', 'slots', 'warehouseUnitsDic'],
  data () {
    return {
      common,
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      localStorage,
      deleteData: {},
      offset: 0
    }
  },
  watch: {
    selected (val) {
      this.$emit('selected', val)
    },
    currentPage (val) {
      this.$emit('currentPage', val)
    }
  },
  methods: {
    showPackages (unit) {
      if (unit.name === 'slots') {
        this.unitViewActive = true
        this.unitObj = unit
      }
    },
    openNotificationFailureData (order) {
      common.openNotificationFailureData(this, order)
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.$emit('currentPage', 1)
      this.$emit('maximumItems', val)
      this.$emit('offset', this.offset)
      this.$emit('loadFirstMileOrders')
    },
    disableRemoveOrMoveOrders (order) {
      if (((order.tracking_number[0] === '2' || (order.tracking_number[0] === '3' && order.tracking_number[1] === '2')) && order.cash_amount > 0 && this.taskListInfoData.custody_from_khazna > 0)
       || order.status === 'collected' || order.status === 'collection_attempted' || order.status === 'out_for_delivery' || order.status === 'delivery_attempted' || order.status === 'return_attempted' || order.status === 'picked_up' || order.status === 'delivered' || order.status === 'returned') {
        return true
      }
    },
    removeAttribute (event) {
      event.preventDefault()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getOrderOperationsStatusColor (order) {
      return common.getOrderStatusColor(order.operationsStatus)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(this.getStatusLabel(order) ? this.getStatusLabel(order) : '')
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    viewShipment (data) {
      this.$router.push({
        name: 'head-of-fleet-order',
        params: {
          warehouseID: this.$route.params.warehouseID,
          taskListID: this.$route.params.taskListID,
          type: data.tracking_number[0] === '2' || (data.tracking_number[0] === '3' && data.tracking_number[1] === '2') ? 'returns' : data.tracking_number[0] === '4' ? 'cash-collections' : 'delivery-orders',
          orderID: data.id
        }
      }).catch(() => {})
    },
    confirmRemoveDeliveryStatus (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure to remove delivery stauts?',
        accept: this.removeDeliveryStatus
      })
    },
    confirmRemovePickedUpStatus (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure to remove picked up stauts?',
        accept: this.removePickedUpStatus
      })
    },
    removePickedUpStatus () {
      if ((this.deleteData.status === 'picked_up' || this.deleteData.status === 'in_transit') && this.taskListInfoData.status === 'in_progress' && new Date().getHours() < 22) {
        sendRequest(false, false, this, `api/v1/tracking/orders/${this.deleteData.id}/pickedup/`, 'delete', null, true,
          () => {
            this.$vs.notify({
              color:'success',
              title:i18nData[this.$i18n.locale]['Success'],
              text:i18nData[this.$i18n.locale]['Status'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
              position: 'top-center'
            })
            this.$emit('loadShipments')
          }
        )
      }
    },
    removeDeliveryStatus () {
      if (this.deleteData.status === 'delivered' && this.taskListInfoData.status === 'in_progress' && !this.taskList.cashCollected && new Date().getHours() < 22) {
        sendRequest(false, false, this, `api/v1/tracking/orders/${this.deleteData.id}/delivered/`, 'delete', null, true,
          () => {
            this.$vs.notify({
              color:'success',
              title:i18nData[this.$i18n.locale]['Success'],
              text:i18nData[this.$i18n.locale]['Status'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
              position: 'top-center'
            })
            this.$emit('loadShipments')
          }
        )
      } else {
        this.$vs.notify({
          color:'danger',
          title:i18nData[this.$i18n.locale]['Failed'],
          text:i18nData[this.$i18n.locale]['Task sheet already closed.'],
          position: 'top-center'
        })
      }
    },
    moveOrderToTaskSheet (data) {
      this.$emit('activeTaskFlag', true)
      this.$emit('taskSheetOrder', [data])
      this.$emit('sequence', this.taskSheetOrders.length > 0 ? this.taskSheetOrders[this.taskSheetOrders.length - 1].sequence : 0)
    },
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination,
    ShipbluCard
  }
}
</script>